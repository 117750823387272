<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO.IMPRIMIR_RELATORIO_ESTOQUE')"
      :subtitulo="$t('ACADEMICO.IMPRIMIR_RELATORIO_ESTOQUES')"
      :breadcrumbs="breadcrumbs"
    />
    <b-row>
      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="peridioInicio"
          v-model="selecionados.dataInicio"
          :label="'Período inicio'"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-date-only-btn
          ref="periodoFim"
          v-model="selecionados.dataFim"
          :label="'Período fim'"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="descontos"
          v-model="relatorioEstoqueLayoutDados"
          :label="$t('ACADEMICO.LAYOUT')"
          :options="opcoes.estoqueLayout"
          required
        />
      </b-col>

      <b-col cols="12" md="auto" class="mt-3">
        <b-button
          class="botao-acao-filtro w-100"
          variant="info"
          @click="realizarImpressaoRelatorioEstoque"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';
import mensagem from '@/common/utils/mensagem';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Services:
import EstoqueService from '@/common/services/estoque/estoque.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import { InputSelectMultiple, InputDateOnlyBtn } from '@/components/inputs';

export default {
  components: {
    InputSelectMultiple,
    InputDateOnlyBtn,
    Topbar,
  },

  template: {},
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('SUPRIMENTOS.SUPRIMENTOS') },
        { titulo: this.$t('MENU.RELATORIO_ESTOQUE') },
      ],
      selecionados: {},
      relatorioEstoqueLayoutDados: null,
      opcoes: {
        estoqueLayout: [],
      },
    };
  },
  mounted() {
    this.getEstoquelayout();
  },
  methods: {
    validarFormulario() {
      if (this.selecionados.periodoFim < this.selecionados.periodoInicial) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('ACADEMICO.DATAS_INVALIDAS')
        );
        return;
      }
      return helpers.validarFormulario(this.$refs);
    },
    realizarImpressaoRelatorioEstoque() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      if (this.relatorioEstoqueLayoutDados.id == 1) {
        this.selecionados.relatorioEstoqueLayout = 'EstoqueComMovimentacao';
      } else {
        this.selecionados.relatorioEstoqueLayout = 'EstoqueSemMovimentacao';
      }

      EstoqueService.obterRelatorioEstoque(this.selecionados)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getEstoquelayout() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('estoque-layout')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.estoqueLayout = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.ajuste-fino-btn {
  margin-top: 29px;
}
</style>
